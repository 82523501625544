.mainBox {
  display: flex;
  flex-wrap: wrap;
}

.sub-box {
  margin: 20px;
  padding: 10px;
  /* font-size: 10px; */
  box-shadow: 0px 0px 2px darkgray;
  background-color: rgb(238, 238, 238);
  font-weight: 500;
  color: black;
  width: 300px;
  height: 150px;
  border-radius: 2px;
}

.dataRow {
  display: flex;
  font-size: 10px;
  justify-content: space-between;
  border-bottom: 1px solid darkgray;
  margin: 0px 5% 0px 0%;
}

.title {
  margin: 30px;
}

.boxTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

img {
  margin: 0px 10px 0px 10px;
}

.navBarTransacties {
  display: flex;
  background-color: #1e8ed4;
  padding: 10px;
  margin: 20px;
  margin-top: 0px;
  align-items: center;
  color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  justify-content: space-between;
}

.datepicker {
  border: none;
  box-shadow: 0px 0px 2px gray;
  padding: 5px;
  margin: 10px;
}

.dateSelect {
  display: flex;
  align-items: center;
}

.datedropdown {
  border: 2px solid lightgray;
  display: fixed;
}

.option {
  display: flex;
  position: relative;
  display: inline-block;
  border: none;
  width: 100%;
  padding: 5px;
  background-color: transparent;
  color: black;
  text-decoration: none;
}

.option:hover {
  background-color: darkgray;
  color: white;
  cursor: pointer;
  transition: .01s;
}

.optionValue {
  color: black;
  padding: 10px;
  text-decoration: none;
}

.dropdownDateSelect {
  position: relative;
  display: inline-block;
  align-items: center;
}

.dropdownMerchants {
  position: relative;
  display: inline-block;
  align-items: center;
}

.dropdownDateSelect-content {
  display: none;
  position: absolute;
  right: 0px;
  box-shadow: 0px 0px 2px gray;
  background-color: white;
  width: 400%;
  z-index: 1;
}

.dropdownMerchants-content {
  display: none;
  flex-wrap: wrap;
  position: absolute;
  left: 0px;
  box-shadow: 0px 0px 2px gray;
  background-color: white;
  width: 200%;
  z-index: 1;
}

.dropdownDateSelect:hover .dropdownDateSelect-content {
  display: block;
}

.dropdownMerchants:hover .dropdownMerchants-content {
  display: block;
}

.companyname {
  margin: 20px;
  margin-bottom: 10px;
  background-color: #1e8ed4;
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 20px;
}

.unknownMethod {
  display: block;
  align-items: center;
  margin: 0px;
}

.groupTitle {
  margin: 20px;
  width: 100%;
}